import React, {Component} from "react";
import {Link} from "react-router-dom";
import LocalStorage from "../../util/localStorage";
import {logout, logoutClear} from "../../data/actions/user";
import Resources from "../../data/services/resources";
import {checkPerm, READ_PERM} from "../../util/util";

export default class PublicLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logout: false,
            toggleMenu: true
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.user.logout) {
            return {logout: true};
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.logout !== this.state.logout && !!this.state.logout) {
            LocalStorage.clearAllExcept([
                'username'
            ]);
            LocalStorage.remove('user');
            this.props.dispatch(logoutClear());
            this.props.history.push("/login");
        }
    }

    render() {
        const {translate} = this.props;

        const loggedIn = !!LocalStorage.get('user');

        const MenuActive = this.props.menuActiveResource;
        const socialIcons = (
            <div className="social-icons">
                <ul className="list-inline">
                    <li className="facebook"><a data-toggle="tooltip" data-placement="top"
                                                title="Facebook"
                                                href="https://www.facebook.com/"><i
                        className="fa fa-facebook"></i></a></li>
                    <li className="linkedin"><a data-toggle="tooltip" data-placement="top"
                                               title="Linkedin" href="https://www.linkedin.com/company/activ8doo/"><i
                        className="fa fa-linkedin"></i></a></li>
                    <li className="instagram"><a data-toggle="tooltip" data-placement="top"
                                                 title="Instagram" href="https://www.instagram.com/"><i
                        className="fa fa-instagram"></i></a></li>

                </ul>
            </div>
        );

        return (
            <React.Fragment>
                <div id="wrapper" className={this.props.addClass}>
                    <div className="topbar">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-sm-12 text-left">
                                    <div className="topmenu">
                                        {!loggedIn && (
                                            <React.Fragment>
                                                <span className=""><i className="fa fa-lock"></i> <Link
                                                    to="/login">{translate("text.login")}</Link></span>

                                                <span className=""><i className="fa fa-envelope"></i> <Link
                                                    to="/register">{translate("text.register")}</Link></span>
                                            </React.Fragment>
                                        )}
                                        {loggedIn && (
                                            <React.Fragment>
                                                <span className=""><i
                                                    className="fa fa-user"></i> {LocalStorage.get('user').Contact.user_fname}</span>

                                                <span className=""><i className="fa fa-quit"></i> <span
                                                    className="link-like-element has-cursor"
                                                    onClick={() => this.props.dispatch(logout({}))}>{translate("app.logout")}</span></span>
                                            </React.Fragment>
                                        )}

                                        {checkPerm(Resources.AdminDashboard, READ_PERM) && (
                                            <React.Fragment>
                                                 <span className=""><i className="fa fa-edit"></i> <Link
                                                     to="/dashboard">{translate("text.admin")}</Link></span>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12 text-right">
                                    {socialIcons}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="logo-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="site-logo">
                                        <Link to="/" className="inline-block">
                                            <img src="https://activ8.rs/public/images/logo.png" width="150px" style={{padding: "20px 0 0 0"}} alt=""/>
                                        </Link>
                                        <div className={"inline-logo inline-block"}>
                                            <span className={"main-color margin-bottom-10 block"}>Budite spremni</span>
                                            <div>na promene</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <header className="header" onClick={() => this.setState({toggleMenu: !this.state.toggleMenu})}>
                        <div className="container">
                            <nav className="navbar navbar-default yamm">
                                <i class="fas fa-bars"></i>
                                <div id="navbar"
                                     className={`navbar-collapse ${this.state.toggleMenu ? "collapse" : ""}`}>
                                    <ul className="nav navbar-nav">
                                        <li className={(!MenuActive ? ("active-link") : "")}><Link
                                            to="/">{translate("text.home")}</Link></li>
                                        <li className={(MenuActive == Resources.CoursesList ? ("active-link") : "")}>
                                            <Link to="/courses">{translate("text.courses")}</Link></li>
                                        <li className={(MenuActive == Resources.AboutPage ? ("active-link") : "")}><Link
                                            to="/about">{translate("text.about")}</Link></li>
                                       {/* <li className={(MenuActive == Resources.Conferences ? ("active-link") : "")}><Link
                                            to="/conferences">{translate("text.conferences")}</Link></li>*/}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </header>

                    {this.props.children}

                    <footer className="footer">
                        <div className="container">
                            <div className="row">

                                <div className="col-md-3 col-md-6 col-xs-12">
                                    <div className="widget text-center">
                                        <h2 className="related-title">
                                        </h2>
                                        <img  width="150px" src={"https://activ8.rs/public/images/logo.png"}/>
                                    </div>
                                </div>

                                <div className="col-md-6 col-md-6 col-xs-12">
                                    <div className="widget">
                                        <h2 className="related-title">
                                            <span>{translate("text.about_system")}</span>
                                        </h2>

                                        <div className="text-widget">
                                            <p>{translate("text.about_system_text")}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 col-md-6 col-xs-12">
                                    <div className="widget">
                                        <h2 className="related-title">
                                            <span>{translate("text.contact")}</span>
                                        </h2>
                                        <ul className="contact-details">
                                            <li><i className="fa fa-link"></i> <a href={translate("text.base_site")}
                                                                                  target="_blank">{translate("text.base_site")}</a>
                                            </li>
                                            <li><i className="fa fa-envelope-o"></i> <a
                                                href={`mailto:${translate("text.base_email")}`}>{translate("text.base_email")}</a>
                                            </li>
                                            <li><i className="fa fa-phone"></i> {translate("text.base_tel")}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>

                    <div className="logo-wrapper bottombar">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-sm-12 text-center">
                                    <div className="site-logo">
                                        <a className="navbar-brand" href="index.html">© E-Learning ACTIV8
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="topbar copyrights">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-sm-12 text-left ">
                                    <div className="topmenu">
                                        <span><a href="/"><i
                                            className="fa fa-home"></i> {translate("text.home")}</a></span>
                                        <span><a href="/policyofprivacy"><i
                                            className="fa fa-briefcase"></i> {translate("text.terms_of_use")}</a></span>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12 text-right">
                                    {socialIcons}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
