import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/public-layout";

class PolicyOfData extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {translate} = this.props;

        return (
            <PublicLayout {...this.props}>
                <section className="section bgw">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 bg-light">
                                <div className="ht-100v d-flex align-items-center justify-content-center">
                                    <h3>Obaveštenje o obradi ličnih podataka</h3>
                                    <p>Registracijom na platformu ACTIV8 E-Learning za elektronsku edukaciju i komunikaciju dajem saglasnost da ACTIV8 doo prikuplja i obrađuje moje lične podatke navedene u registracionom formularu.</p>
                                    <p>Lični podaci koje unosite u ovaj formular obrađuje ACTIV8 doo sa sedištem na adresi Bulevar Umetnosti 27, Novi Beograd, Beograd, a za potrebe evidentiranja polaznika kurseva/učesnika događaja, izdavanje sertifikata za uspešno završen kurs, statističke obrade podataka i efikasnije organizacije događaja za koji se prijavljujete. Prikupljeni lični podaci neće se koristiti u druge svrhe sem u svrhu za koju su prikupljeni, niti će se ustupati trećim licima i upotrebiti na drugi načine od prethodno navedenih.</p>
                                    <p>U svakom trenutku imate pravo da zahtevate da ACTIV8 doo obriše, isprave ili obustave obradu Vaših ličnih podataka, u kom slučaju nas možete kontaktirati putem elektronske pošte <a href = "mailto:slobodan@activ8.rs">slobodan@activ8.rs</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(PolicyOfData);