import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/public-layout";

class PolicyOfPrivacy extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {translate} = this.props;

        return (
            <PublicLayout {...this.props}>
                <section className="section bgw">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 bg-light">
                                <div className="ht-100v d-flex align-items-center justify-content-center">
                                    <h2>ACTIV8 KOLAČIĆI</h2>
                                    <h3>O OVOJ POLITICI KOLAČIĆA</h3>
                                    <p>Ova politika kolačića objašnjava šta su to kolačići i kako ih koristimo. Trebali biste pročitati ovu politiku da biste razumeli koju vrstu kolačića koristimo, koje podatke prikupljamo pomoću kolačića i kako se te informacije dalje koriste. Za dodatne informacije o tome kako koristimo, čuvamo i obezbeđujemo vaše lične podatke, pogledajte  naše Obaveštenje o obradi ličnih podataka.</p>
                                    <p>Ova Politika vam takođe otkriva koja su vaša prava u vezi sa ličnim podacima koje nam dajete. Ako imate bilo kakvih pitanja o ovoj Politici kolačića, kontaktirajte nas na email adresu: <a href = "mailto:slobodan@activ8.rs">slobodan@activ8.rs</a></p>
                                    <h3>ŠTA SU TO “KOLAČIĆI”?</h3>
                                    <p>Kolačići su mali tekstualni fajlovi koje internet sajtovi postavljaju na računare posetilaca kako bi sačuvali različite informacije, obično specifične za samog posetioca ili za uređaj koji koristite za pregled.</p>
                                    <p>Imaju široku primenu jer pomažu rad platforme i pospešuju efikasnost putem prepoznavanja i pamćenja informacija i na taj način čine Vaše korišćenje platforme lakšim (kroz pamćenje Vaših izabranih postavki). Kolačići nam takođe pomažu da saznamo koji delovi sajta su korisni, a kojima treba poboljšanje, kao i da omogućimo za Vas ciljane oglase kroz praćenje Vašeg korištenja sajta.</p>
                                    <p>Postoje različite vrste kolačića. Neki kolačići dolaze direktno sa naše veb stranice, dok drugi dolaze od trećih strana (naših partnera) koji postavljaju kolačiće na našu veb stranicu.</p>
                                    <p>Različiti kolačići se mogu čuvati tokom različitog vremenskog perioda u vašem internet pretraživaču odnosno uređaju. Kolačići za sesiju se brišu sa računara ili uređaja kada zatvorite svoj veb pretraživač. Trajni kolačići ostaće pohranjeni na vašem računaru ili uređaju dok ne budu izbrisani ili dok im ne istekne rok trajanja.</p>
                                    <h3>KAKO KORISTIMO KOLAČIĆE?</h3>
                                    <p>Kolačiće koristimo kako bismo poboljšali vaše iskustvo surfovanja tako što:</p>
                                    <ul style={{listStylePosition: "inside"}}>
                                        <li>kada se prijavite prepoznajemo koja su vaša preferirana podešavanja;</li>
                                        <li>dajemo Vam doživljaj surfovanja koji je jedinstven za vas i tako što vam prezentujemo sadržaj za koji verujemo da poboljšava vaše korisničko iskustvo u korišćenju našeg veb sajta;</li>
                                        <li>analiziramo način na koji koristite našu veb stranicu, a što nam pomaže da rešimo bilo koje probleme;</li>
                                        <li>koji mogu nastati kao i da nadgledamo performanse našeg veb sajta.</li>
                                    </ul>
                                    <h3>KOJE VRSTE KOLAČIĆA KORISTIMO?</h3>
                                    <h5>Neophodni</h5>
                                    <p>Neki kolačići su neophodni da biste mogli da iskusite potpunu funkcionalnost naše platforme. Na primer, ovi kolačići vam omogućavaju da se prijavite na svoj korisnički nalog i da bezbedno upravljate kursevima/događajima za koje ste se prijavili. Bez ovih kolačića, neki delovi naše platforme jednostavno neće raditi onako kako bi trebali.</p>
                                    <h5>Podešavanja</h5>
                                    <p>Ovi kolačići (koji se još nazivaju i funkcionalni kolačići) omogućavaju našem veb sajtu da “zapamti” vaša podešavanja, pomažući vam da prilagodite svoje iskustvo na našoj veb lokaciji. Na taj način vas se možemo “setiti” kada se vratite na našu stranicu i prilagoditi je Vašim prethodnim zahtevima.</p>
                                    <h5>Statistika</h5>
                                    <p>Ovi kolačići nam govore o tome kako koristite naš veb sajt i pomažu nam da poboljšamo i sam veb sajt, kao i Vaše korisničko iskustvo. Na primer, ovi kolačići broje posetioce naše platforme i vide kako se posetioci kreću kada ga koriste. To nam pomaže da poboljšamo način na koji naša internet lokacija funkcioniše, na primer, osiguravajući da korisnici lako pronađu ono što traže.</p>
                                    <h3>Trenutno ne koristimo vrste kolačića pobrojane u nastavku:</h3>
                                    <h5>Marketing</h5>
                                    <p>Ovi kolačići (koji se nazivaju i reklamni kolačići) koriste se za isporučivanje oglašenog sadržaja koji je relevantan za Vas i mogu se koristiti za praćenje efikasnosti naših reklamnih kampanja na veb sajtu. Ove kolačiće stavljaju na našu lokaciju naši partneri pružaoci određenih marketinških usluga i oni mogu upamtiti vašu aktivnost veb pretraživanja na našem veb sajtu i te informacije se mogu koristiti za bolje razumevanje Vas i Vaših potreba, na primer – demografija (odakle dolazite na naš veb sajt), starost itd. Samo anonimne informacije se zadržavaju i koriste na ovaj način i samo sa ciljem da se Vama pruži sadržaj koji je najviše relevantan za vas.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(PolicyOfPrivacy);